import { Text, Theme } from '@audi/audi-ui-react';
import React, { memo } from 'react';
import styled from 'styled-components';
import { convertRichText } from '../../utils/convertRichText';
import { isClient } from '../../utils/utils';

const StyledText = styled(Text)`
  & p {
    margin: 0;
    padding: 0;

    & + p {
      margin-block-start: var(${({ theme }: { theme: Theme }) => theme.responsive.spacing.m});
    }
  }
`;

export interface RichTextProps {
  readonly text: string;
}

export const RichText = memo(function RichText({ text }: RichTextProps) {
  return <StyledText>{isClient ? convertRichText(text) : text}</StyledText>;
});
