/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import { Text } from '@audi/audi-ui-react';
import {
  renderTextWithFootnotesReferencesV2,
  cleanupEmissionConsumptionMarkup,
} from '@oneaudi/feature-app-utils';
import { ConsumptionsAndEmissions } from '@oneaudi/vue-formatter-service';
import styled from 'styled-components';

export interface ConsumptionAndEmissionsProps {
  caeIds?: string[];
  preloadedCaeData: Record<string, ConsumptionsAndEmissions>;
  onCaeReady?: () => void;
}

interface CaeData {
  consumption: string | React.ReactNode[];
  emissions: string | React.ReactNode[];
  emissionClass: string | React.ReactNode[];
  dischargedCO2Class?: string | React.ReactNode[];
  dischargedConsumption?: string | React.ReactNode[];
}

const StyledConsumptionAndEmissions = styled.div`
  sup {
    margin-left: 2px;
    a {
      font-weight: normal;
    }
  }

  margin-inline: 0;
`;

export const ConsumptionAndEmissions: React.FC<ConsumptionAndEmissionsProps> = ({
  caeIds,
  preloadedCaeData,
  onCaeReady,
}) => {
  if (!caeIds || !caeIds.length) return null;
  const [caeData] = React.useState<CaeData[]>(
    caeIds
      .map((caeId) => preloadedCaeData[caeId])
      .filter((cae) => {
        return (
          cae?.formattedConsumption ||
          cae?.formattedEmission ||
          cae?.formattedCo2Class ||
          cae?.formattedDischargedConsumption ||
          cae?.formattedDischargedCo2Class
        );
      })
      .map((cae) => {
        return {
          consumption: cae.formattedConsumption
            ? renderTextWithFootnotesReferencesV2(
                cleanupEmissionConsumptionMarkup(cae.formattedConsumption),
              )
            : '',
          emissions: cae.formattedEmission
            ? renderTextWithFootnotesReferencesV2(
                cleanupEmissionConsumptionMarkup(cae.formattedEmission),
              )
            : '',
          emissionClass: cae.formattedCo2Class
            ? renderTextWithFootnotesReferencesV2(
                cleanupEmissionConsumptionMarkup(cae.formattedCo2Class),
              )
            : '',
          dischargedConsumption: cae.formattedDischargedConsumption
            ? renderTextWithFootnotesReferencesV2(
                cleanupEmissionConsumptionMarkup(cae.formattedDischargedConsumption),
              )
            : '',
          dischargedCO2Class: cae.formattedDischargedCo2Class
            ? renderTextWithFootnotesReferencesV2(
                cleanupEmissionConsumptionMarkup(cae.formattedDischargedCo2Class),
              )
            : '',
        };
      }),
  );

  React.useEffect(() => {
    if (caeData?.length) onCaeReady?.();
  }, [caeData?.length]);

  if (!caeData?.length) return null;

  return (
    <>
      {caeData.map((x, i) => (
        <StyledConsumptionAndEmissions key={`cae${i}`}>
          <Text key={`c${i}`} variant="copy2" spaceStackEnd="xs">
            {x.consumption}
            {x.emissions && <span>; {x.emissions}</span>}
            {x.emissionClass && <span>; {x.emissionClass}</span>}
            {x.dischargedConsumption && <span>; {x.dischargedConsumption}</span>}
            {x.dischargedCO2Class && <span>; {x.dischargedCO2Class}</span>}
          </Text>
        </StyledConsumptionAndEmissions>
      ))}
    </>
  );
};

ConsumptionAndEmissions.displayName = 'ConsumptionAndEmissions';
