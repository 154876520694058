import { Theme as AudiTheme } from '@audi/audi-ui-react';

import { APP_ID, NODE_ENVIRONMENT, STAGE } from '../../environment';

export const isDarkTheme = (theme: AudiTheme): boolean => theme.name === 'Audi Dark Theme';

export const APP_NAME = APP_ID.replace('oneaudi-feature-app-', '').replace(/-/g, ' ');

export const enum QueryParam {
  Test = 't',
  Debug = 'd_fa',
}

export const getParam = (paramName: string): string | null => {
  if (typeof window !== 'undefined') {
    return new URLSearchParams(window.location.search.substr(1)).get(paramName);
  }

  return null;
};

export const isTestMode = (): boolean => getParam(QueryParam.Test) === 'true';

export const isDevMode = NODE_ENVIRONMENT === 'development';

export const isStageDev = STAGE === 'dev' && NODE_ENVIRONMENT === 'production';

export const isDebugMode = (): boolean => isDevMode || getParam(QueryParam.Debug) === 'true';

export const isClient = typeof window !== 'undefined';
