import { Breakpoints } from '@audi/audi-ui-react';
import { AssetTypeVideo } from '../../types';
import { parseAssetValue } from './parseAssetValue';

export type Video = {
  alt: string;
  aspectRatio: string;
  src: string;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  nativeControls?: boolean;
  poster?: string;
};

export type VideoPlayerConfig = {
  videos: Partial<Breakpoints<Video>>;
  playsInline: boolean;
  cover?: boolean;
  isFormatable?: boolean;
};

export function getVideoPlayerConfig(video: AssetTypeVideo): VideoPlayerConfig {
  const parsedPoster = parseAssetValue(video.poster?.assetValue).src;
  const parsedVideo = parseAssetValue(video.src.assetValue);

  // separately provided poster image url overrides the VMS thumbnail image used as poster image
  const posterSrc = parsedPoster || parsedVideo.posterSrc;

  return {
    cover: true,
    playsInline: true,
    videos: {
      xs: {
        alt: video.alt,
        poster: posterSrc,
        aspectRatio: '3/4',
        autoPlay: video.autoPlay,
        loop: video.loop,
        muted: video.muted,
        src: parsedVideo.src,
        nativeControls: video.controls,
      },
    },
  };
}
