/* eslint-disable camelcase, @typescript-eslint/ban-ts-comment */
import { ContentFragment, InvalidContentError, isContentFragment } from '@oneaudi/falcon-tools';
import { AccordionContent, AccordionSectionContent, HeadlineTag } from '../../types';

export interface FalconContent extends Partial<ContentFragment> {
  __type: 'aem-headless';
  fields: {
    headlineOptions_headline: string;
    headlineOptions_tag: HeadlineTag;
    accordionOptions_hasMultipleItemsAllowed: boolean;
    accordionOptions_isAutoOpen: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sections: [];
    themeOption_theme: 'light' | 'dark';
  };
}

export interface EditorJsonContent extends AccordionContent {
  __type?: 'editor.json';
}

interface Section {
  fields: {
    label: string;
    body: string;
    asset: {
      fields: {
        assetType: 'video' | 'image' | 'none';
        assetSrc: {
          path: string;
        };
        assetAlt: string;
        autoPlay: boolean;
        loop: boolean;
        muted: boolean;
        controls: boolean;
        preserveVideoWidth: boolean;
        poster?: {
          path: string;
        };
      };
    };
    consumptionAndEmissions: string[];
    disclaimers: string[];
    links_0_text?: string;
    links_0_href?: string;
    links_0_openInTabOrLayer?: 'page' | 'tab';
    links_1_text?: string;
    links_1_href?: string;
    links_1_openInTabOrLayer?: 'page' | 'tab';
  };
}

const normalizeAccordionContent = (sections: Section[]): AccordionSectionContent[] => {
  if (!sections) return [];

  return sections.map((section: Section) => {
    const {
      label,
      body,
      asset,
      consumptionAndEmissions,
      disclaimers,
      links_0_text,
      links_0_href,
      links_0_openInTabOrLayer,
      links_1_text,
      links_1_href,
      links_1_openInTabOrLayer,
    } = section.fields;

    const links: { href: string; text: string; openInTabOrLayer: 'page' | 'tab' }[] = [];

    if (links_0_text && links_0_href) {
      links.push({
        href: links_0_href,
        text: links_0_text,
        openInTabOrLayer: links_0_openInTabOrLayer || 'page',
      });
    }

    if (links_1_text && links_1_href) {
      links.push({
        href: links_1_href,
        text: links_1_text,
        openInTabOrLayer: links_1_openInTabOrLayer || 'page',
      });
    }

    const normalizedSection: AccordionSectionContent = {
      label,
      body,
      asset: {
        assetType: asset?.fields.assetType || 'none',
        src: {
          assetValue: asset?.fields.assetSrc ? asset.fields.assetSrc.path : '',
        },
        alt: asset?.fields.assetAlt,
        poster: asset?.fields.poster ? { assetValue: asset.fields.poster.path } : undefined,
        autoPlay: asset?.fields.autoPlay,
        loop: asset?.fields.loop,
        muted: asset?.fields.muted,
        controls: asset?.fields.controls,
        preserveVideoWidth: asset?.fields.preserveVideoWidth,
      },
      consumptionAndEmissions,
      disclaimers,
      links,
    };

    return normalizedSection;
  });
};

function isFalconContent(content: unknown): content is FalconContent {
  return Boolean(isContentFragment(content));
}

export function mapContent(content: EditorJsonContent | FalconContent): EditorJsonContent {
  if (!content || Object.keys(content).length === 0) {
    // eslint-disable-next-line no-console
    console.warn('No Editor content found for Accordion FA');
    return {} as AccordionContent;
  }
  if (content.__type !== 'aem-headless') {
    return content;
  }

  if (!isFalconContent(content)) {
    // Throw `InvalidContentError` here if provided content
    // is not valid. This will display as a special error message
    // in the frontend on author environments.
    throw new InvalidContentError('Content is invalid.');
  }

  const normalizedContent: EditorJsonContent = {
    headlineOptions: {
      headline: content.fields.headlineOptions_headline,
      tag: content.fields.headlineOptions_tag,
    },
    accordionOptions: {
      hasMultipleItemsAllowed: content.fields.accordionOptions_hasMultipleItemsAllowed,
      isAutoOpen: content.fields.accordionOptions_isAutoOpen,
    },
    sections: normalizeAccordionContent(content.fields.sections as Section[]),
    themeOption: {
      theme: content.fields.themeOption_theme,
    },
  };
  return normalizedContent;
}
