import { Button, ButtonGroup } from '@audi/audi-ui-react';
import React from 'react';
import { UeElement } from '@oneaudi/falcon-tools';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { LinkProps } from '../../../types';
import { TrackingClickEvent, useTracking } from '../../tracking';

export interface CTAsProps {
  readonly links: LinkProps[];
  headline: string;
  trackingService?: TrackingServiceV2;
}

const CTAs: React.FC<CTAsProps> = ({ links, headline, trackingService }: CTAsProps) => {
  if (links.length === 0) {
    return null;
  }

  const { sendClickEvent } = useTracking(trackingService);

  return (
    <ButtonGroup variant="text-buttons" spaceStackStart="l" data-testid="ctas">
      {links.map(({ href, text, openInTabOrLayer }, i) => {
        return (
          <Button
            variant="text"
            newWindow={openInTabOrLayer === 'tab'}
            href={href}
            // eslint-disable-next-line react/no-array-index-key
            key={`${i}-${text}`}
            data-testid="cta"
            onClick={() => {
              const event: TrackingClickEvent = {
                linkLabel: text,
                linkUrl: href,
                elementName: 'text link',
                headline,
                clickEventName: 'click on text link',
              };
              return sendClickEvent(event);
            }}
          >
            <UeElement type="text" property={`links_${i}_text`} label={`Label (${i + 1})`}>
              {text}
            </UeElement>
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default CTAs;
