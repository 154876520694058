import React from 'react';
import styled from 'styled-components';
import { Theme } from '@audi/audi-ui-react';
import DesignTokens from '@audi/audi-ui-design-tokens';

import { UeContainer, UeElement, UeReference } from '@oneaudi/falcon-tools';
import { AssetType, FeatureAppMeta } from '../../../types';
import { Image } from './Image';
import { Video } from './Video';

interface SideBySideProps {
  asset: AssetType;
  children: React.ReactNode;
  sectionIndex: number;
  meta: FeatureAppMeta;
}

const StyledSideBySideContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${DesignTokens.BREAKPOINT_L}px) {
    flex-direction: row;
    column-gap: var(${({ theme }: { theme: Theme }) => theme.responsive?.spacing.l});
  }
`;

const TextChild = styled.div`
  @media (min-width: ${DesignTokens.BREAKPOINT_L}px) {
    flex: 66%;
  }
`;

const StyledMediaChild = styled.div`
  margin-top: var(${({ theme }: { theme: Theme }) => theme.responsive?.spacing.l});

  @media (min-width: ${DesignTokens.BREAKPOINT_L}px) {
    flex: 34%;
    margin-top: inherit;
  }
`;

export const SideBySide = ({ asset, children, sectionIndex, meta }: SideBySideProps) => {
  return (
    <StyledSideBySideContainer>
      <TextChild>{children}</TextChild>
      <StyledMediaChild>
        <UeContainer propertyPath={`sections.${sectionIndex}.asset`} label="Media">
          {asset.assetType === 'image' && (
            <UeReference
              propertyPath={`sections.${sectionIndex}.asset`}
              label="Images"
              behavior="component"
            >
              <Image asset={asset} />
            </UeReference>
          )}
        </UeContainer>
        <UeContainer propertyPath={`sections.${sectionIndex}.asset`} label="Media">
          {asset.assetType === 'video' && (
            <UeReference
              propertyPath={`sections.${sectionIndex}.asset`}
              behavior="component"
              label="Videos"
            >
              <UeElement type="media" property="assetSrc" label="Video">
                <Video asset={asset} sectionIndex={sectionIndex} meta={meta} />
              </UeElement>
            </UeReference>
          )}
        </UeContainer>
      </StyledMediaChild>
    </StyledSideBySideContainer>
  );
};
