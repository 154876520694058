import { Layout } from '@audi/audi-ui-react';
import React from 'react';
import { ConsumptionsAndEmissions } from '@oneaudi/vue-formatter-service';
import { AccordionSectionContent } from '../../../types';
import { ConsumptionAndEmissions } from './ConsumptionAndEmissions';
import { Disclaimers } from './Disclaimers';

export interface LegalDataProps {
  consumptionsAndEmissions: AccordionSectionContent['consumptionAndEmissions'];
  disclaimers: AccordionSectionContent['disclaimers'];
  preloadedCaeData: Record<string, ConsumptionsAndEmissions> | undefined;
  onCaeReady?: () => void;
}

export const LegalData: React.FC<LegalDataProps> = React.memo(
  ({ consumptionsAndEmissions, disclaimers, preloadedCaeData, onCaeReady }: LegalDataProps) => {
    if (!consumptionsAndEmissions.length && !disclaimers.length) return null;
    return (
      <Layout spaceStackStart="m" direction="column" columnGap="xs" data-testid="legalData">
        {preloadedCaeData && consumptionsAndEmissions?.length > 0 && (
          <ConsumptionAndEmissions
            caeIds={consumptionsAndEmissions}
            preloadedCaeData={preloadedCaeData}
            onCaeReady={onCaeReady}
          />
        )}
        {disclaimers?.length > 0 && <Disclaimers disclaimers={disclaimers} />}
      </Layout>
    );
  },
);

export default LegalData;
