import * as React from 'react';
import styled, { AnyStyledComponent, createGlobalStyle } from 'styled-components';
import {
  audiDarkTheme,
  audiLightTheme,
  Accordion as AudiAccordion,
  AccordionSection as AudiAccordionSection,
  ThemeProvider,
  Text,
} from '@audi/audi-ui-react';
import type { Theme } from '@audi/audi-ui-react';
import {
  COLOR_BASE_BRAND_BLACK,
  COLOR_BASE_BRAND_WHITE,
  PAGE_MARGIN_XS,
  PAGE_MARGIN_S,
  PAGE_MARGIN_M,
  PAGE_MARGIN_L,
  PAGE_MARGIN_XL,
  PAGE_MARGIN_XXL,
} from '@audi/audi-ui-design-tokens';
import {
  useContent,
  renderTextWithFootnotesReferencesV2,
  getConsumptionsAndEmissionsWithIdentifiers,
} from '@oneaudi/feature-app-utils';
import {
  ConsumptionsAndEmissions,
  VueFormatterServiceInterfaceV1,
} from '@oneaudi/vue-formatter-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { UeContainer, UeElement, UeReference } from '@oneaudi/falcon-tools';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import type { AccordionContent, FeatureAppMeta } from '../../../types';

import { isDarkTheme, isDebugMode } from '../../utils/utils';
import { APP_ID } from '../../../environment';
import { SideBySide } from './SideBySide';
import { RichText } from './RichText';
import LegalData from './LegalData';
import CTAs from './CTAs';
import { FalconContent, mapContent } from '../../contentMapping/contentMapping';
import { TrackingClickEvent, useTracking } from '../../tracking';

const GlobalStyle = createGlobalStyle`
  .audi-footnote-anchor__text {
    vertical-align: super !important;
  }
  .audi-j-footnote-reference {
    color: inherit !important;
    font-weight: normal !important;
    padding: 0 !important;
    text-decoration: none !important;
    border-bottom: 0 !important;
}
div {
  white-space: normal;
}
`;

const StyledContainer = styled.div`
  font-family: var(
    ${({ theme }: { theme: Theme }) => theme.responsive?.typography.copy1.fontFamily}
  );
  font-stretch: var(
    ${({ theme }: { theme: Theme }) => theme.responsive?.typography.copy1.fontStretch}
  );
  line-height: var(
    ${({ theme }: { theme: Theme }) => theme.responsive?.typography.copy1.lineHeight}
  );
  background-color: ${({ theme }: { theme: Theme }) =>
    isDarkTheme(theme) ? COLOR_BASE_BRAND_BLACK : COLOR_BASE_BRAND_WHITE};
  color: ${({ theme }: { theme: Theme }) =>
    isDarkTheme(theme) ? COLOR_BASE_BRAND_WHITE : COLOR_BASE_BRAND_BLACK};
  padding-inline-start: ${PAGE_MARGIN_XS}px;
  padding-inline-end: ${PAGE_MARGIN_XS}px;

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.s}px) {
    padding-inline-start: ${PAGE_MARGIN_S}px;
    padding-inline-end: ${PAGE_MARGIN_S}px;
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    padding-inline-start: ${PAGE_MARGIN_M}px;
    padding-inline-end: ${PAGE_MARGIN_M}px;
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.l}px) {
    padding-inline-start: ${PAGE_MARGIN_L}px;
    padding-inline-end: ${PAGE_MARGIN_L}px;
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.xl}px) {
    padding-inline-start: ${PAGE_MARGIN_XL}px;
    padding-inline-end: ${PAGE_MARGIN_XL}px;
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.xxl}px) {
    padding-inline-start: ${PAGE_MARGIN_XXL}px;
    padding-inline-end: ${PAGE_MARGIN_XXL}px;
  }
`;

const StyledAccordion = styled(AudiAccordion as AnyStyledComponent)`
  > div {
    white-space: normal;
  }
  // target the span that contains the footnote anchor
  span span {
    display: flex;
  }
  // add margin-left to the footnote asterix
  span span sup {
    margin-left: 5px;
  }
`;

export const Accordion = ({
  themeOption,
  headlineOptions,
  accordionOptions,
  sections,
  meta,
  vueFormatterService,
  localeService,
  trackingService,
}: AccordionContent & {
  meta: FeatureAppMeta;
  vueFormatterService?: VueFormatterServiceInterfaceV1;
  localeService?: LocaleServiceV1;
  trackingService?: TrackingServiceV2;
}): React.ReactElement => {
  const headlineTag = headlineOptions && headlineOptions.tag ? headlineOptions.tag : 'h2';
  const ref = React.useRef(null);
  const [preloadedCaeData, setPreloadedCaeData] =
    React.useState<Record<string, ConsumptionsAndEmissions>>();

  React.useEffect(() => {
    // get all CaE ids from all sections for loading all CaE data at once
    const caeIds = sections.map((section) => section.consumptionAndEmissions).flat(1);

    if (localeService && vueFormatterService && caeIds.length > 0) {
      const loadCaeData = async () => {
        const _caeData = await getConsumptionsAndEmissionsWithIdentifiers(
          caeIds,
          vueFormatterService,
          localeService,
        );

        setPreloadedCaeData(_caeData);
      };

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadCaeData();
    }
  }, []);

  const isDefaultOpen = accordionOptions && accordionOptions.isAutoOpen;
  const firstSectionHasCaE =
    Boolean(localeService) &&
    Boolean(vueFormatterService) &&
    sections.length > 0 &&
    sections[0].consumptionAndEmissions?.length > 0;
  const isWaitingForCaE = firstSectionHasCaE && isDefaultOpen && !preloadedCaeData;

  const { sendReadyEvent, registerImpressionTracking, sendClickEvent } =
    useTracking(trackingService);

  if (typeof window !== 'undefined') {
    // skip tracking on the server side where window object is undefined

    React.useEffect(() => {
      sendReadyEvent();
    }, []);

    React.useEffect(() => {
      if (ref?.current)
        registerImpressionTracking(ref, {
          value: headlineOptions.headline || '',
        });
    }, [ref]);
  }

  const sendAccordionClickEvent = (open: boolean, label: string) => {
    const event: TrackingClickEvent = {
      linkLabel: label,
      linkUrl: '',
      elementName: 'other',
      headline: headlineOptions.headline || '',
      clickEventName: `${open ? 'open' : 'close'} accordion`,
    };
    return sendClickEvent(event);
  };

  return (
    <div ref={ref} data-testid="accordion">
      <GlobalStyle />
      <ThemeProvider
        theme={themeOption && themeOption.theme === 'light' ? audiLightTheme : audiDarkTheme}
      >
        <StyledContainer>
          {headlineOptions.headline && (
            <Text variant="order2" as={headlineTag} spaceStackEnd="xl">
              <UeElement type="text" property="headlineOptions_headline" label="Headline">
                {renderTextWithFootnotesReferencesV2(headlineOptions.headline || '')}
              </UeElement>
            </Text>
          )}

          <UeContainer propertyPath="sections" label="Sections">
            <StyledAccordion
              data-testid="audi-accordion"
              multiple={accordionOptions && accordionOptions.hasMultipleItemsAllowed}
            >
              {sections.map((section, sectionIndex) => {
                return (
                  <AudiAccordionSection
                    key={section.label}
                    id={section.label}
                    label={
                      <UeReference propertyPath={`sections.${sectionIndex}`} as="div">
                        <UeElement type="text" property="label" label="Label">
                          {section.label}
                        </UeElement>
                      </UeReference>
                    }
                    headingLevel="h3"
                    defaultOpen={isDefaultOpen && sectionIndex === 0}
                    onToggle={(open: boolean) => sendAccordionClickEvent(open, section.label)}
                  >
                    <UeReference propertyPath={`sections.${sectionIndex}`} as="span">
                      {section?.asset?.assetType && section?.asset?.assetType !== 'none' ? (
                        <SideBySide asset={section?.asset} sectionIndex={sectionIndex} meta={meta}>
                          <UeElement type="text" property="body" label="Copy">
                            <RichText text={section.body} />
                          </UeElement>
                        </SideBySide>
                      ) : (
                        <UeElement type="text" property="body" label="Copy">
                          <RichText text={section.body} />
                        </UeElement>
                      )}
                    </UeReference>
                    <UeContainer propertyPath={`sections.${sectionIndex}`}>
                      <UeReference propertyPath={`sections.${sectionIndex}`} as="div" label="CTAs">
                        {section.links && section.links.length > 0 ? (
                          <CTAs
                            links={section.links}
                            headline={headlineOptions.headline || ''}
                            trackingService={trackingService}
                          />
                        ) : null}
                      </UeReference>
                    </UeContainer>
                    {!isWaitingForCaE && (
                      <LegalData
                        consumptionsAndEmissions={section.consumptionAndEmissions}
                        disclaimers={section.disclaimers}
                        preloadedCaeData={preloadedCaeData}
                      />
                    )}
                  </AudiAccordionSection>
                );
              })}
            </StyledAccordion>
          </UeContainer>
        </StyledContainer>
      </ThemeProvider>
    </div>
  );
};

export const AccordionLoader = ({
  meta,
  initialContent,
  vueFormatterService,
  localeService,
  trackingService,
}: {
  meta: FeatureAppMeta;
  initialContent?: AccordionContent;
  vueFormatterService: VueFormatterServiceInterfaceV1;
  localeService: LocaleServiceV1;
  trackingService?: TrackingServiceV2;
}): React.ReactElement | null => {
  const rawContent = useContent<AccordionContent | FalconContent>() || initialContent;
  const content = mapContent(rawContent as AccordionContent | FalconContent);

  /* eslint-disable no-console */
  if (isDebugMode()) console.debug(`${APP_ID} ->`, { content });
  if (!content) return null;
  return (
    <Accordion
      {...content}
      meta={meta}
      localeService={localeService}
      vueFormatterService={vueFormatterService}
      trackingService={trackingService}
    />
  );
};
