import React from 'react';
import styled from 'styled-components';

import { UeElement } from '@oneaudi/falcon-tools';
import { AssetTypeImage } from '../../../types';
import { parseAssetValue } from '../../utils/parseAssetValue';

interface ImageProps {
  asset: AssetTypeImage;
}

const ImageContainer = styled.div`
  width: 100%;

  img {
    width: 100%;
    object-fit: cover;
  }
`;

export const Image = ({ asset }: ImageProps) => {
  return (
    <ImageContainer>
      <UeElement type="media" property="assetSrc" label="Image">
        {(ueElemProps) => (
          <img
            src={asset?.src ? parseAssetValue(asset.src.assetValue).src : asset?.src?.assetValue}
            alt={asset.alt}
            {...ueElemProps}
          />
        )}
      </UeElement>
    </ImageContainer>
  );
};
