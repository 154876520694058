import * as React from 'react';
import type { FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import type { ContentServiceV1 } from '@oneaudi/content-service';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import { ContentContextProvider, FootnoteContextProvider } from '@oneaudi/feature-app-utils';
import type { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { AudiVideoServiceInterfaceV1 } from '@oneaudi/video-service';

import { AccordionContent } from '../types';
import { AccordionLoader } from './components/Accordion/Accordion';

interface FeatureServiceDependencies extends FeatureServices {
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 'audi-tracking-service'?: TrackingServiceV2;
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 's2:serialized-state-manager'?: SerializedStateManagerV1;
  readonly 'audi-video-service': AudiVideoServiceInterfaceV1;
  readonly 'dbad:audi-vue-formatter-service': VueFormatterServiceInterfaceV1;
  readonly 'locale-service': LocaleServiceV1;
  readonly 'audi-render-mode-service': RenderModeServiceV1;
}

// eslint-disable-next-line import/no-default-export
export default {
  dependencies: {
    externals: {
      react: '*',
      'react-dom': '*',
      'styled-components': '*',
      '@audi/audi-ui-react': '*',
      '@feature-hub/react': '*',
    },
    featureServices: {
      'audi-content-service': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
      'locale-service': '^1.0.0',
    },
  },
  optionalDependencies: {
    featureServices: {
      'audi-tracking-service': '^2.0.0',
      'audi-video-service': '^1.0.0',
      'dbad:audi-vue-formatter-service': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },
  create: ({
    featureServices: {
      'audi-content-service': contentService,
      'audi-footnote-reference-service': footnoteReferenceServiceScopeManager,
      'locale-service': localeService,
      'dbad:audi-vue-formatter-service': vueFormatterService,
      'audi-render-mode-service': renderModeService,
      'audi-tracking-service': trackingService,
    },
    config,
    featureAppId: id,
  }: FeatureAppEnvironment<FeatureServiceDependencies, AccordionContent>) => {
    if (trackingService) {
      /* eslint-disable no-param-reassign */
      trackingService.featureAppName = __FEATURE_APP_NAME__;
    }
    return {
      render: () => (
        <ContentContextProvider contentService={contentService}>
          <FootnoteContextProvider
            // eslint-disable-next-line max-len
            footnoteReferenceService={footnoteReferenceServiceScopeManager?.getDefaultScopeRefService()}
          >
            <UniversalEditorProvider
              contentService={contentService}
              renderModeService={renderModeService}
            >
              <AccordionLoader
                initialContent={config}
                meta={{ id }}
                localeService={localeService}
                vueFormatterService={vueFormatterService}
                trackingService={trackingService}
              />
            </UniversalEditorProvider>
          </FootnoteContextProvider>
        </ContentContextProvider>
      ),
    };
  },
};
